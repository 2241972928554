import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { LoaderComponent } from "../loader/loader.component";
import CreditCardAppIcon from "../../assets/CreditCardAppIcon.svg";
import DepositAppIcon from "../../assets/DepositAppIcon.svg";
import { Button, Icon } from "@creditonebank/c1b-components";
import IdleTimer from "react-idle-timer";
import { ReactComponent as FailIcon } from "../../assets/Fail.svg";

const Dashboard = ({
  creditCardAppUrl,
  depositAppUrl,
  creditCardAppRefUrl,
  depositAppRefUrl,
  sessionExpiredRedirectUri,
  sessionIdleTime,
  sessionTimer,
}) => {
  sessionIdleTime = Number(sessionIdleTime);
  sessionTimer = Number(sessionTimer);
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [showTransactional, setShowTransactional] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isIdle, setIdle] = useState(false);
  const [timeout, setTimeout] = useState(sessionTimer);

  const handleOnIdle = () => {
    setIdle(true); // Set the timeout to 0 to indicate session expiration
    setPopupVisible(true);
  };

  const handleAction = () => {
    if (timeout === 0) {
      // logoutRedirect();
    } else {
      setIdle(false);
      setPopupVisible(false);
      setTimeout(sessionTimer);
    }
  };

  function getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [key, value] = el.split("=");
      cookie[key.trim()] = value;
    });
    return cookie[cookieName];
  }

  const setBlur = (bg) => {
    const head = document.getRootNode().getElementsByTagName("header");
    const foot = document.getRootNode().getElementsByTagName("footer");
    head[0].classList.add("blurred");
    foot[0].classList.add("blurred");
    bg[0].classList.add("blurred");
  }

  const removeBlur = (bg) => {
    const head = document.getRootNode().getElementsByTagName("header");
    const foot = document.getRootNode().getElementsByTagName("footer");
    head[0]?.classList.remove("blurred");
    foot[0]?.classList.remove("blurred");
    bg[0]?.classList.remove("blurred");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isIdle && timeout > 0) {
        setTimeout(timeout - 1);
      }
      else if (timeout === 0) {
        logoutRedirect();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isIdle, timeout]);

  useEffect(() => {
    const bg = document.getElementsByClassName("bg");
    isPopupVisible ? setBlur(bg) : removeBlur(bg);
    
  }, [isPopupVisible])

  useEffect(() => {
    const referrer = getCookie("ref_Url");
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser()?.then((info) => {
        setUserInfo(info);
        if (
          info?.productEntitlements.some(
            (product) => product === "CREDITCARD"
          ) &&
          info?.productEntitlements.length > 1
        ) {
          setShowDashboard(true);
        } else if (
          info?.productEntitlements.some(
            (product) => product === "CREDITCARD"
          ) &&
          info?.productEntitlements.length === 1
        ) {
          if (referrer && referrer === depositAppRefUrl)
            setShowTransactional(true);
          else {
            setShowLoader(true);
            localStorage.clear();
            window.location.href = creditCardAppUrl;
          }
        } else {
          if (referrer && referrer === creditCardAppRefUrl)
            setShowDeposit(true);
          else {
            setShowLoader(true);
            localStorage.clear();
            window.location.href = depositAppUrl;
          }
        }
      });
    }
  }, [
    authState,
    oktaAuth,
    creditCardAppUrl,
    depositAppUrl,
    creditCardAppRefUrl,
    depositAppRefUrl,
    showDashboard,
  ]);

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const logoutRedirect = async () => {
    await oktaAuth.signOut({ postLogoutRedirectUri: sessionExpiredRedirectUri });
  }

  const logout = async () => {
    await oktaAuth.signOut();
  }

  const handleClickCreditCardApp = () => {
    localStorage.clear();
    window.location.href = creditCardAppUrl;
  }

  const handleClickDepositApp = () => {
    localStorage.clear();
    window.location.href = depositAppUrl;
  }

  if (!authState) {
    return (
      <div className="bg">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <IdleTimer
        timeout={sessionIdleTime} // 1 minute in milliseconds
        onIdle={handleOnIdle}
      />
      {isPopupVisible && (
        <>
          {timeout !== 0 ?
            <div className="session-timeout-popup refresh">
              <div className="popup-content">
                <div className="timer">
                  <FailIcon className="fail" />
                  <h4>Your session is about to expire</h4>
                  <p className="subtext">To protect your account, you will signed out in:</p>
                  {timeout < 10 ? <p className="time">00:0{timeout} seconds</p> : <p className="time">00:{timeout} seconds</p>}
                </div>
                <div className="buttons">
                  <Button onClick={handleAction} className="still-here">I'm still here</Button>
                  <Button size="small" variant="link" onClick={logout} className="sign-out">Sign out</Button>
                </div>
              </div>
            </div>
            : <>
              <div className="session-timeout-popup refresh loaders">
                <div className="popup-content">
                  <div className="timer">
                    <LoaderComponent />
                  </div>
                </div>
              </div>
            </>}
        </>
      )}

      {isPopupVisible && <div className="overlay"></div>}
      <div className="bg">
        {authState.isAuthenticated && !userInfo && (
          <div className="bg">
            <LoaderComponent />
          </div>
        )}
        <>
          {authState.isAuthenticated && userInfo && (
            <>
              {showDashboard && (
                <div className="dashboard-container">
                  <p className="dashboard-headline">
                    Where would you like to go ?
                  </p>
                  <div className="product-entitlement-container">
                    <a onClick={handleClickCreditCardApp} className="product-style">
                      <Icon
                        src={CreditCardAppIcon}
                        className="product-image-style"
                      ></Icon>
                      <span className="product-text-style">Credit cards</span>
                    </a>

                    <a onClick={handleClickDepositApp} className="product-style">
                      <Icon
                        src={DepositAppIcon}
                        className="product-image-style"
                      ></Icon>
                      <span className="product-text-style">CD & Savings</span>
                    </a>
                  </div>
                </div>
              )}

              {showDeposit && (
                <div className="bg">
                  <div className="credit-card-container">
                    <img src={DepositAppIcon} className="product-icon-style" alt="set-up-icon" />
                    <p className="dashboard-headline-one">
                      Looking for your deposit account?
                    </p>
                    <p className="credit-card-container-text">
                      Please click below to sign in to your deposit account. We
                      apologize for any inconvenience.
                    </p>
                    <Button className="button-style-fp" onClick={handleClickDepositApp}>Go to deposit</Button>
                  </div>
                </div>
              )}

              {showTransactional && (
                <div className="bg">
                  <div className="credit-card-container">
                    <img src={CreditCardAppIcon} className="product-icon-style" alt="set-up-icon" />
                    <p className="dashboard-headline-one">
                      Looking for Credit card ?{" "}
                    </p>
                    <p className="credit-card-container-text">
                      Please click below to sign in to your credit card account.
                      We apologize for any inconvenience.
                    </p>
                    <Button className="button-style-fp" onClick={handleClickCreditCardApp}>Go to credit card</Button>
                  </div>
                </div>
              )}

              {showLoader && (
                <div className="bg">
                  <LoaderComponent />
                </div>
              )}
            </>
          )}
          {!authState.isAuthenticated && (
            <>
              <LoaderComponent />
              {login()}
            </>
          )}
        </>
      </div>
    </>
  );
};
export default Dashboard;
